import React from 'react';

// Themes
import './Logo.css';

function Logo() {
    return (
	<img alt='dstylz-logo' className='logo' src={process.env.PUBLIC_URL + 'static/images/dstylz.png'} />
    );
}

export default Logo;
