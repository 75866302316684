import React from 'react';

// Theme
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Routes
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

// Pages
import Home from './Home';
import Logo from './Logo';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Dashboard from './Dashboard';

// Components
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function App() {
    // Get system preferences for 'light' vs 'dark' mode
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Memoize the theme
    const theme = React.useMemo(
	() =>
	createTheme({
	    palette: {
		mode: prefersDarkMode ? 'dark' : 'light',
		primary: {
		    main: '#673ab7',
		},
		secondary: {
		    main: '#bdbdbd',
		},
	    },
	}),
	[prefersDarkMode]
    );

    return (
	<ThemeProvider theme={theme}>
	    <BrowserRouter>
		<Routes>
		    <Route path="/" element={<Home />}>
			<Route index element={<Logo />} />
			<Route path="/signin" element={<SignIn />} />
			<Route path="/signup" element={<SignUp />} />
			<Route path="/dashboard" element={<Dashboard />} />
			<Route path="*" element={<NoMatch />} />
		    </Route>
		</Routes>
	    </BrowserRouter>
	</ThemeProvider>
    );
}

function NoMatch() {
    return (
	<Box
	    sx={{
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '100vh',
		backgroundColor: 'mode',
	    }}
	>
	    <Typography variant="h1">
		<Link to="/" style={{ textDecoration: 'none' }}>
		    😜 Oops! Poops! 💩
		</Link>
	    </Typography>
	</Box>
    );
}

export default App;
